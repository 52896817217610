import React from "react";
import Window from "./Window";
import { Experience } from "../interfaces";
import * as styles from "../styles/ExperienceBlock.module.scss";

const ExperienceBlock = (props: { details: Experience }) => {
  const [show, setShow] = React.useState("default");
  const handleYellow = () => {
    setShow("yellow");
    typeof window !== "undefined" &&
      window?.gtag("event", "Yellow Circle Click", {
        event_category: "Experience Window",
        event_label: props.details.organization,
      });
    setTimeout(() => {
      setShow((s) => (s === "yellow" ? "default" : s));
    }, 2500);
  };
  const handleGreen = () => {
    setShow("green");
    typeof window !== "undefined" &&
      window?.gtag("event", "Green Circle Click", {
        event_category: "Experience Window",
        event_label: props.details.organization,
      });

    setTimeout(() => {
      setShow((s) => (s === "green" ? "default" : s));
    }, 2500);
  };

  return (
    <div
      style={{
        maxWidth: 500,
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: 24,
      }}
    >
      <Window fade={true} yellowButton={handleYellow} greenButton={handleGreen}>
        <div className={styles.content_wrapper}>
          {show === "default" ? (
            <p>
              <span className={styles.strong}>
                {props.details.organization}
              </span>
              {", "}{props.details.time}
            </p>
          ) : show === "yellow" ? (
            <p>
              <span>
                Really? You&apos;re trying to minimize my acheivements?
              </span>
            </p>
          ) : show === "green" ? (
            <p>
              <span>
                Want to learn more? I&apos;d love to tell you about{" "}
                {props.details.organizationShort}. Let&apos;s chat!
              </span>
            </p>
          ) : null}

          <p>{show === "default" ? props.details.position : null}</p>
          <div className="center-x">
            <img src={props.details.logo} alt={props.details.alt} />
          </div>
        </div>
      </Window>
    </div>
  );
};

export default ExperienceBlock;
