import React from "react";
import SocialIcons from "./SocialIcons";
import * as styles from "../styles/Contact.module.scss";
import { OutboundLink } from "gatsby-plugin-google-gtag";
const Contact = () => {
  return (
    <div className={styles.contact} id="contact">
      <h5>Find me on LinkedIn and GitHub!</h5>
      <SocialIcons size={48} />
      <p>Have something you want to chat about? Let&apos;s talk!</p>
      <OutboundLink
        href="mailto:patrickdemers6@gmail.com"
        className={styles.email}
      >
        patrickdemers6@gmail.com
      </OutboundLink>
    </div>
  );
};
export default Contact;
