import React from "react";
import Marquee from "react-fast-marquee";

const Skills = () => {
  if (typeof window === "undefined") return null;
  return (
    <Marquee
      style={{
        marginTop: window?.innerWidth > 768 ? 96 : 48,
        marginBottom: window?.innerWidth > 768 ? 96 : 48,
        height: window?.innerWidth > 768 ? 200 : 150,
        maxWidth: "max-content",
      }}
      gradientColor={[28, 30, 34]}
      loop={0}
      speed={45}
      gradientWidth={window?.innerWidth > 768 ? 200 : 100}
    >
      <div
        style={{
          height: window?.innerWidth > 768 ? 200 : 150,
          width: "100%",
          display: "flex",
        }}
      >
        {icons.map((icon, i) => (
          <div
            key={i}
            style={{
              verticalAlign: "top",
              display: "inline-block",
              textAlign: "center",
              width: window?.innerWidth > 768 ? 200 : 150,
              marginLeft: window?.innerWidth > 768 ? 96 : 24,
              marginRight: window?.innerWidth > 768 ? 96 : 24,
            }}
          >
            <img
              src={icon.path}
              alt={icon.name + " Logo"}
              style={{
                display: "block",
                height: window?.innerWidth > 768 ? "80%" : "70%",
                maxWidth: window?.innerWidth > 768 ? 200 : 150,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <span
              style={{
                display: "block",
                width: "inherit",
                position: "absolute",
                bottom: 0,
              }}
            >
              {icon.name}
            </span>
          </div>
        ))}
      </div>
    </Marquee>
  );
};

const icons = [
  { path: "/skills/node.svg", name: "Node.js" },
  { path: "/skills/gcp.svg", name: "Google Cloud Platform" },
  { path: "/skills/docker.svg", name: "Docker" },
  { path: "/skills/js.svg", name: "JavaScript" },
  { path: "/skills/cpp.svg", name: "C++" },
  { path: "/skills/vscode.svg", name: "Visual Studio Code" },
  { path: "/skills/react.svg", name: "React" },
  { path: "/skills/terraform.svg", name: "Terraform" },
  { path: "/skills/python.svg", name: "Python" },
  { path: "/skills/okta.svg", name: "Okta" },
  { path: "/skills/java.svg", name: "Java" },
  { path: "/skills/gitlab.svg", name: "GitLab" },
  { path: "/skills/figma.svg", name: "Figma" },
  { path: "/skills/gatsby.svg", name: "Gatsby" },
  { path: "/skills/google-analytics.svg", name: "Google Analytics" },
  { path: "/skills/jira.svg", name: "Jira" },
  { path: "/skills/bootstrap.svg", name: "Bootstrap" },
];

export default Skills;
