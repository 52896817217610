import React from "react";
import Project from "./Project";
import { Project as ProjectI } from "../interfaces";
import * as styles from "../styles/Projects.module.scss";
import { Container } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
const projects: ProjectI[] = [
  {
    title: "Ames Ride",
    description:
      "When using public transportation, knowing when your bus will arrive is key. This application shows the realtime location and arrival times of busses in Ames, Iowa.",
    tags: ["react native", "api", "open source", "ci/cd"],
    link: "https://github.com/patrickdemers6/AmesRide",
    cta: "View it on GitHub",
    image: (
      <StaticImage
        src={"../images/ames_ride.png"}
        alt="Ames Ride promotional graphic."
      />
    ),
  },
  {
    title: "Monikers App",
    description:
      "Ever played the card game Monikers? Well, now there is an interactive app that allows you to play with friends, no matter where you are.",
    tags: ["android", "spring boot", "design", "school"],
    link: "https://youtu.be/mFwCZfnl6DI",
    cta: "Video Demo",
    image: (
      <StaticImage
        src={"../images/monikers.png"}
        alt={
          'Monikers app picture. Phone next to text reading "Play Interactively with Friends".'
        }
      />
    ),
  },
  {
    title: "Digital Billboards",
    description:
      "This project enabled digital displays across multiple locations to show information received from a Google Slides document. This reduced labor costs and increased the accuracy of posted information.",
    tags: [
      "fleet management",
      "docker",
      "google cloud platform",
      "raspberry pi",
    ],
    image: (
      <StaticImage
        src={"../images/digital_billboard.png"}
        alt="Digital billboard example slide."
      />
    ),
  },
  {
    title: "Ellen Wengert Cosmetics",
    description:
      "A minimilastic black and white website showcasing cosmetic offerings to clients in a tri-state area.",
    tags: ["react", "ssr", "cms", "ci/cd", "design"],
    link: "https://bellisimaself.com/",
    cta: "Check it out",
    image: (
      <StaticImage
        src={"../images/bellisima.png"}
        alt="Ellen Wengert Cosmetics website screenshot."
      />
    ),
  },
  // {
  //   title: "At-Home Weather",
  //   description:
  //     "When COVID hit, news stations needed to stay on the air, but also protect their employees. I setup a solution that allowed the Chief Meteorologist at a dominant #1 station to live broadcast the weather from home.",
  //   tags: ["obs", "production", "news", "live"],
  //   image: (
  //     <StaticImage
  //       src={"../images/at_home_weather.jpg"}
  //       alt="Image of Ron Demers in front of greenscreen while doing at-home weather."
  //     />
  //   ),
  // },
  {
    title: "Brian's Car Collection",
    description:
      "A custom website for a collector to sell their antique cars on. The website generated leads from across the country. More than ten cars were sold through the website, equating to over a quarter million dollars in sales. The website is no longer available since the entire collection was sold.",
    tags: ["react", "cms"],
    image: (
      <StaticImage
        src={"../images/brians_car_collection.jpg"}
        alt="Brian's Car Collection website screenshot."
      />
    ),
  },
];

const Projects = () => {
  return (
    <div className={styles.wrapper} id="projects">
      <Container>
        <h3>Latest Projects</h3>
        {projects.map((p, i) => (
          <Project key={i} data={p} side={i % 2 ? "left" : "right"} />
        ))}
      </Container>
    </div>
  );
};

export default Projects;
