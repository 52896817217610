import React from "react";
import * as style from "../styles/Top.module.scss";

const Top = (props: any) => {
    return (<div className={style.top}>
        {props.children}
    </div>);
}
Top.propTypes = {
    children: React.Component
}

export default Top;