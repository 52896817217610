import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import MainWindow from "./MainWindow";
import WelcomeText from "./WelcomeText";

const TopSection = () => {
  return (
    <>
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "100%",
          height: "100%",
        }}
      >
        <Container style={{ maxWidth: 2000 }}>
          <Row>
            <Col xs={12} md={12} xl={6}>
              <WelcomeText />
            </Col>
            <Col xs={12} md={12} xl={6}>
              <MainWindow />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TopSection;
