import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import { Github, Icon as BootstrapIcon, Linkedin } from "react-bootstrap-icons";
import * as style from "../styles/SocialIcons.module.scss";

const socialProfiles: SocialProfile[] = [
  {
    icon: Linkedin,
    alt: "LinkedIn Profile",
    link: "https://linkedin.com/in/patrick-demers6",
  },
  {
    icon: Github,
    alt: "GitHub Profile",
    link: "https://github.com/patrickdemers6",
  },
];

const SocialIcons = (props: SocialIconsProps) => {
  return (
    <>
      {socialProfiles.map(({ icon: Icon, link, alt }, i) => {
        return (
          <OutboundLink
            href={link}
            className={style.iconLink}
            about={alt}
            key={i}
            target="_blank"
            rel="noreferrer"
            aria-label={alt}
          >
            <Icon size={props.size} className={style.icon} />
          </OutboundLink>
        );
      })}
    </>
  );
};

export default SocialIcons;

interface SocialProfile {
  icon: BootstrapIcon;
  alt: string;
  link: string;
}

interface SocialIconsProps {
  size: number;
}
