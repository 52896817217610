import * as React from "react";
import type { HeadFC } from "gatsby";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import "../styles/global.scss";
import BackgroundCircles from "../components/BackgroundCircles";
import Top from "../hoc/Top";
import TopSection from "../components/TopSection";
import Experiences from "../components/Experiences";
import Projects from "../components/Projects";
import Contact from "../components/Contact";
import Skills from "../components/Skills";

const pageStyles = {
  color: "#232129",
  padding: 0,
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <meta
          name="description"
          content="Patrick Demers is a full stack software engineer located in Sioux City, Iowa."
        />
      </Helmet>

      <Top>
        <Header />
        <TopSection />
        <Experiences />
        <Projects />
        <Skills />
        <Contact />
      </Top>
      <BackgroundCircles />
    </main>
  );
};

export default IndexPage;

export const Head: HeadFC = () => (
  <title>Patrick Demers - Software Engineer</title>
);
