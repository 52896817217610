import React from "react";
import { Button, Col, Collapse, Container, Row } from "react-bootstrap";
import { Link } from "react-scroll";
import { List, X } from "react-bootstrap-icons";
import * as headerStyles from "../styles/Header.module.scss";
import { centerY, hideOnSm, showOnlySm } from "../utilities/classNames";
import SocialIcons from "./SocialIcons";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const navigationLinks: { name: string; to?: string; link?: string }[] = [
  { name: "Experience", to: "professional-experience" },
  { name: "Projects", to: "projects" },
  { name: "Contact", to: "contact" },
  { name: "Resume", link: "/Patrick_Demers_Resume.pdf" },
];

const Header = () => {
  const [open, setOpen] = React.useState(false);

  const toggleMenu = () => {
    setOpen((o) => !o);
  };
  return (
    <div className={headerStyles.headerWrapper}>
      <Container fluid>
        <Row>
          <Col xs={"auto"} className={"center-y"}>
            <h1 className={["site-title", "bold"].join(" ")}>Patrick Demers</h1>
          </Col>

          <Col className={[showOnlySm, centerY, "text-end"].join(" ")}>
            <Button
              onClick={toggleMenu}
              about="Toggle hamburger menu"
              aria-label="Toggle hamburger menu"
            >
              {!open ? (
                <List color="black" size={32} />
              ) : (
                <X size={32} color="black" />
              )}
            </Button>
          </Col>
          <Col className={["center-y", hideOnSm].join(" ")}>
            <div className={headerStyles.topLinks}>
              {navigationLinks.map((link, i) =>
                link.to ? (
                  <Link to={link.to} offset={-30} key={i} href="">
                    <span>{link.name}</span>
                  </Link>
                ) : (
                  <OutboundLink href={link.link} target="_blank" rel="noreferrer" key={i}>
                    <span>{link.name}</span>
                  </OutboundLink>
                )
              )}
            </div>
          </Col>
          <Col md={"auto"} className={[hideOnSm, centerY].join(" ")}>
            <SocialIcons size={32} />
          </Col>
        </Row>
      </Container>
      <Collapse in={open} timeout={1500}>
        <div>
          <div className={headerStyles.topLinksMobile}>
            {navigationLinks.map((link, i) =>
              link.to ? (
                <Link
                  to={link.to}
                  offset={-140}
                  key={i}
                  onClick={toggleMenu}
                  href=""
                >
                  <span>{link.name}</span>
                </Link>
              ) : (
                <a href={link.link} target="_blank" rel="noreferrer" key={i}>
                  <span>{link.name}</span>
                </a>
              )
            )}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default Header;
