import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "../styles/Window.module.scss";

const Window = (props: WindowProps) => {
  return (
    <div className={props.fade ? styles.wrapper_fade : styles.wrapper}>
      <Container fluid>
        <Row>
          <Col xs={props.title ? 3 : 12}>
            <span className={[styles.dot, styles.dotRed].join(" ")} />
            <span
              className={[
                styles.dot,
                styles.dotYellow,
                props.yellowButton ? styles.clickableDot : '',
              ].join(" ")}
              onClick={props?.yellowButton}
            />
            <span
              className={[
                styles.dot,
                styles.dotGreen,
                props.greenButton ? styles.clickableDot : '',
              ].join(" ")}
              onClick={props?.greenButton}
            />
          </Col>
          {props.title && (
            <>
              <Col xs={6} className={"center-y"}>
                {props.title && (
                  <span className={styles.documentTitle}>{props.title}</span>
                )}
              </Col>
              <Col xs={3} />
            </>
          )}
        </Row>
      </Container>
      {props.children}
    </div>
  );
};

interface WindowProps {
  title?: string;
  children: React.ReactNode;
  fade: boolean;
  yellowButton?: () => void;
  greenButton?: () => void;
}

export default Window;
