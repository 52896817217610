import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import { Badge, Col, Container, Row } from "react-bootstrap";
import { Project as ProjectI } from "../interfaces";
import * as styles from "../styles/Project.module.scss";

const Project = (props: { data: ProjectI; side: "left" | "right" }) => {
  return (
    <div className={styles.wrapper}>
      <Container>
        <Row>
          <Col
            xs={{ span: 12, order: 1 }}
            md={{ span: 6, order: props.side === "right" ? 1 : 2 }}
            className={styles.contentRight}
          >
            {props.data.image}
          </Col>
          <Col
            xs={{ span: 12, order: 2 }}
            md={{ span: 6, order: props.side === "right" ? 2 : 1 }}
            style={{ display: "flex" }}
          >
            <Content data={props.data} side={props.side} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const Content = (props: { data: ProjectI; side: "left" | "right" }) => {
  return (
    <div
      className={[
        props.side === "right" ? styles.contentRight : styles.contentLeft,
        "center-y",
      ].join(" ")}
    >
      <h4>{props.data.title}</h4>
      <p>{props.data.description}</p>
      {props.data.tags.map((tag, i) => (
        <div key={i} style={{ display: "inline-block" }}>
          <Badge style={{ marginRight: 12, color: "black" }}>{tag}</Badge>
        </div>
      ))}
      {props.data.link && (
        <OutboundLink
          href={props.data.link}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
        >
          <div className={styles.link}>
            {props.side == "left" && "← "}
            {props.data.cta}
            {props.side == "right" && " →"}
          </div>
        </OutboundLink>
      )}
    </div>
  );
};

export default Project;
