import React from "react";
import * as styles from "../styles/BackgroundCircles.module.scss";

const BackgroundCircles = () => {
  return (
    <div className={styles.background}>
      <div className={styles.topCircle}></div>
      <div className={styles.experienceGradient}></div>
      <div className={styles.bottomCircle}></div>
    </div>
  );
};
export default BackgroundCircles;
