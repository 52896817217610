import React from "react";
import * as styles from "../styles/WelcomeText.module.scss";

const WelcomeText = () => {
  return (
    <div className={[styles.welcomeWrapper, "center-y"].join(" ")}>
      <p className={[styles.accentText, styles.topText].join(" ")}>Hello!</p>
      <h1 className={styles.mainText}>I&apos;m Patrick,</h1>
      <h2>
        <span className={[styles.accentText, styles.hollowText].join(" ")}>
          software engineer.
        </span>
      </h2>
    </div>
  );
};

export default WelcomeText;
