import React from "react";
import ExperienceBlock from "./ExperienceBlock";
import { Experience } from "../interfaces";
import { Button, Col, Container, Row } from "react-bootstrap";
import attImage from "../images/att.jpg";
import teslaImage from "../images/tesla.png";
// import tysonImage from "../images/tyson.png";
import nasaImage from "../images/nasa.jpg";
import * as styles from "../styles/Experiences.module.scss";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const experiences: Experience[] = [
  {
    time: "Summer 2023",
    organization: "Tesla",
    organizationShort: "Tesla",
    position: "Software Engineering Intern",
    alt: "Tesla Logo",
    logo: teslaImage,
  },
  {
    time: "Summer 2022",
    organization: "AT&T",
    organizationShort: "AT&T",
    position: "Software Engineering Intern",
    alt: "AT&T Logo",
    logo: attImage,
  },
  {
    time: "2021 - 2022",
    organization: "NASA",
    organizationShort: "NASA",
    position: "Data Science Intern",
    alt: "NASA Meatball Logo",
    logo: nasaImage,
  },
  // {
  //   time: "2018 - 2021",
  //   organization: "Tyson Foods",
  //   organizationShort: "Tyson",
  //   position: "Software Engineering Intern",
  //   alt: "Tyson Foods Upright Logo",
  //   logo: tysonImage,
  // },
];

const Experiences = () => {
  return (
    <>
      <Container
        style={{ maxWidth: 1800, marginTop: 32 }}
        id="professional-experience"
      >
        <h3 className={styles.sectionTitle}>Professional Experience</h3>
        <Row>
          {experiences.map((e, i) => (
            <Col key={i} sm={12} md={4}>
              <ExperienceBlock details={e} />
            </Col>
          ))}
        </Row>
        <div className={styles.resume}>
          <OutboundLink href="/Patrick_Demers_Resume.pdf" target="_blank">
            <Button variant="primary" color="#FFF" size="lg">
              View my Resume 👏
            </Button>
          </OutboundLink>
        </div>
      </Container>
    </>
  );
};
export default Experiences;
